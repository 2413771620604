<template>
  <Portlet
    :title="$t('menu.applicationSettings')"
    icon="cog"
    class="applicationSettings"
  >
    <template slot="buttons">
      <button 
        class="btn btn-sm btn-primary float-right mt-2 mb-2" 
        @click="showSidebarAdd"
      >
        <font-awesome-icon
          class="mr-1"
          icon="plus"
        />
        <span>
          {{ $t('add') }}
        </span>
      </button>
      <div class="clearfix" />
    </template>
    <applicationSettingsList
      ref="applicationSettingsList"
      @reloadAuditLogs="reloadAuditLogs"
    /> 
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :is-application-setting="true"
      @reload="reloadCollectionList"
    />
  </Portlet>
</template>

<script>
export default {
    name: 'ApplicationSettings',
    components: {
        ApplicationSettingsList: () => import('@/components/ApplicationSettings/ApplicationSettingsList.vue'),
        DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
    },
    metaInfo () {
        return {
            title: this.$t('menu.applicationSettings')
        }
    },
    methods: {
      showSidebarAdd () {
        this.$refs.applicationSettingsList.showSidebarAdd();
      },
      reloadAuditLogs () {
        this.$refs.databaseAuditLogList.reloadAuditLogs();
      },
      reloadCollectionList () {
        this.$refs.applicationSettingsList.reloadApplicationSettings();
      }
    }
}
</script> 